import "./index.css"
import "./common.css"

import { IRegister, rolesObject, textObject } from "types/sharedTypes"

import { useMemo, useState } from "react"
import { useLocation } from "react-router-dom"

import TextContext, { TextsContent } from "context/TextContext"
import { RegistriesContent, RegistriesContext } from "context/RegistriesContext"
import RolesContext, { RolesContent, rolesInitialValue } from "context/RolesContext"

import Header from "./components/header/Header"
import MenuPanel from "./components/menu/MenuPanel"
import MainContent from "./components/main/MainContent"
import Footer from "./components/footer/Footer"

function App() {
    const { pathname } = useLocation()
    const forgotPassword = pathname === "/passwordReset" || pathname.toLocaleLowerCase().includes("/resetpassword")

    const [loggedIn, setLoggedIn] = useState(!!localStorage.getItem("username"))

    const [registries, setRegistries] = useState<IRegister[]>([])
    const [registriesFetched, setRegistriesFetched] = useState(false)
    const registriesContext = useMemo<RegistriesContent>(
        () => ({ registries, setRegistries, registriesFetched, setRegistriesFetched }),
        [registries, registriesFetched]
    )

    const [texts, setTexts] = useState<textObject[]>([])
    const textContext = useMemo<TextsContent>(() => ({ texts, setTexts }), [texts])

    const [roles, setRoles] = useState<rolesObject>(rolesInitialValue)
    const [agvAdmin, setAgvAdmin] = useState(false)
    const [agvAdminFetched, setAgvAdminFetched] = useState(false)
    const rolesContext = useMemo<RolesContent>(
        () => ({ roles, setRoles, agvAdmin, setAgvAdmin, agvAdminFetched, setAgvAdminFetched }),
        [roles, agvAdmin, agvAdminFetched]
    )

    return (
        <div className="pageWrapper">
            <Header setLoggedIn={setLoggedIn} />
            <div className="contentWrapper">
                <RolesContext.Provider value={rolesContext}>
                    <MenuPanel loggedIn={loggedIn} setLoggedIn={setLoggedIn} />
                    {(loggedIn || forgotPassword) && (
                        <RegistriesContext.Provider value={registriesContext}>
                            <TextContext.Provider value={textContext}>
                                <main className="mainContentPadding">
                                    <MainContent />
                                </main>
                            </TextContext.Provider>
                        </RegistriesContext.Provider>
                    )}
                </RolesContext.Provider>
            </div>
            {!loggedIn && !forgotPassword && <Footer />}
        </div>
    )
}

export default App
