import "./ResetPasswordPage.css"

import React, { useState } from "react"
import { useParams } from 'react-router-dom';

import api from "constants/endpoints.constants"
import pageTitles from "constants/pageTitles.constants.json"
import useUpdatePageTitle from "hooks/useUpdatePageTitle"
import { RESET_PASSWORD_TEXTS } from "constants/text.constants"
import { resetPasswordValidationSchema } from "utils/yupSchemas"

import Error from "components/atoms/error/Error"
import Input from "components/atoms/input/Input"
import Button from "components/atoms/button/Button"
import Confirmation from "components/atoms/confirmation/Confirmation"

const ResetPasswordPage = () => {
    const { email, guid } = useParams();

    const [password, setPassword] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [passwordRepeatError, setPasswordRepeatError] = useState('');
    const [validationError, setValidationError] = useState(false)
    const [validationErrorRepeat, setValidationErrorRepeat] = useState(false)

    const [postError, setPostError] = useState("");
    const [resettingPassword, setResettingPassword] = useState(false)
    const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false)

    const hasNumberZeroToNine = (stringValue: string): boolean => /(?=.*\d)/.test(stringValue);
    const hasLowerCaseLetter = (stringValue: string): boolean => /(?=.*[a-z])/.test(stringValue);
    const hasUpperCaseLetter = (stringValue: string): boolean => /(?=.*[A-Z])/.test(stringValue);
    const hasSpecialCharacter = (stringValue: string): boolean => /[!%&#/¤?*,.+_(){}@£$€><:;-]/.test(stringValue);
    
    const isRepeatedPasswordValid = (passwordRep: string, repeatedPassword: string): boolean => {
        if (repeatedPassword === passwordRep) {
            return true;
        } 

        setPasswordRepeatError(RESET_PASSWORD_TEXTS.PASSWORD_DO_NOT_MATCH);
        setValidationErrorRepeat(true);
        return false;
    };

    const isLongerThan5AndShorterThan256 = (passwordLS: string): boolean => {
        if (passwordLS.length < 6) {
            setPasswordError((prev) => `${prev} ${RESET_PASSWORD_TEXTS.PASSWORD_MIN_CHARACTERS}`);
            setValidationError(true);
            return false;
        }
        if (passwordLS.length > 255) {
            setPasswordError((prev) => `${prev} ${RESET_PASSWORD_TEXTS.PASSWORD_MAX_CHARACTERS}`);
            setValidationError(true);
            return false;
        }
        return true;
    };

    const hasForbiddenWords = (passwordFor: string): boolean => {
        const forbiddenWord = /analyskraft/i;

        if (forbiddenWord.test(passwordFor)) {
            setPasswordError((prev) => `${prev} ${RESET_PASSWORD_TEXTS.PASSWORD_NOT_CONTAIN_ANALYSKRAFT}`);
            setValidationError(true);
            return true;
        }

        const emailPattern = /email=(\S*)(?:&)/g;
        const searchStr = window.location.href;
        const emailMatch = emailPattern.exec(searchStr);
        const emailAddress = emailMatch ? emailMatch[1].replace('%40', '@') : '';

        for (let i = 0; i < passwordFor.length; i += 1) {
            const set = passwordFor.length - i > 2 ? passwordFor[i] + passwordFor[i + 1] + passwordFor[i + 2] : '';
            if (set.length === 3 && emailAddress.toLowerCase().includes(set.toLowerCase())) {
                setPasswordError((prev) => `${prev} ${RESET_PASSWORD_TEXTS.PASSWORD_NOT_CONTAIN_EMAIL}`);
                setValidationError(true);
                return true;
            }
        }
        return false;
    };

    const isValidPassword = (passwordValid: string): boolean => {
        let count = 0;
        if (hasNumberZeroToNine(passwordValid)) count += 1;
        if (hasLowerCaseLetter(passwordValid)) count += 1;
        if (hasUpperCaseLetter(passwordValid)) count += 1;
        if (hasSpecialCharacter(passwordValid)) count += 1;
        if (count >= 3) {
            return true;
        } 

        setPasswordError((prev) => `${prev} ${RESET_PASSWORD_TEXTS.PASSWORD_MUST_MEET_CTITERIA}`);
        setValidationError(true);
        return false;
    };

    const isPasswordValid = (passwordCheck: string): boolean => {
        let isValid = true;

        if (!isLongerThan5AndShorterThan256(passwordCheck)) {
            isValid = false;
        }
        if (!isValidPassword(passwordCheck)) {
            isValid = false;
        }
        if (hasForbiddenWords(passwordCheck)) {
            isValid = false;
        }

        return isValid;
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setResettingPassword(true);
        setPasswordError('');
        setPasswordRepeatError('');
        setValidationError(false);
        setValidationErrorRepeat(false);

        if (isPasswordValid(password) && isRepeatedPasswordValid(password, passwordRepeat)) {
            const body = {
                password: password || "",
                passwordRepeat: passwordRepeat || "",
                email: email || "",
                guid: guid || "",
            }

            resetPasswordValidationSchema
                .validate(body, { abortEarly: false })
                .then(() => {
                    const params = new URLSearchParams(body).toString();
                    fetch(`${api.verifyPassword}?${params}`, {
                        method: "GET",
                        credentials: "include",
                        headers: {
                            "Content-Type": "application/json",
                        },
                    })
                    .then((response) => {
                        if (response.ok) return response
                        return Promise.reject(response)
                    })
                    .then(() => {
                        setPostError("")
                        setShowPasswordConfirmation(true)
                        setResettingPassword(false)
                    })
                    .catch(() => {
                        setPostError(RESET_PASSWORD_TEXTS.POST_ERROR)
                    })
                })
                .catch(() => {
                    setPostError(RESET_PASSWORD_TEXTS.POST_ERROR)
                    setResettingPassword(false)
                })            
        }
        else {
            setResettingPassword(false)
        }
    };

    useUpdatePageTitle(pageTitles.RESET_PASSWORD_PAGE_TITLE);

    return (
        <div className="maxWidth1000">
            <div className="resetPasswordPageHeader">
                <h1>{RESET_PASSWORD_TEXTS.H1_TITLE}</h1>
                <p>{email}</p>
            </div>
            {postError && <Error>{postError}</Error>}
            {showPasswordConfirmation && (
                <Confirmation>{`${RESET_PASSWORD_TEXTS.PASSWORD_IS_CHANGED}`}</Confirmation>
            )}
            {!postError && !showPasswordConfirmation && (
                <div className="containerResetPassword">
                    {RESET_PASSWORD_TEXTS.H1_PARAGRAPH}
                    <form onSubmit={handleSubmit}>
                        <label htmlFor="newpassword">{RESET_PASSWORD_TEXTS.NEW_PASSWORD}</label>
                        <Input
                            type="password"
                            name="newpassword"
                            id="newpassword"
                            placeholder={RESET_PASSWORD_TEXTS.NEW_PASSWORD_PLACEHOLDER}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            maxLength={255}
                            isErroneous={validationError}
                            errorMessage={passwordError}
                        />
                        <label htmlFor="passwordRepeat">{RESET_PASSWORD_TEXTS.REPEAT_NEW_PASSWORD}</label>
                        <Input
                            type="password"
                            name="passwordRepeat"
                            id="passwordRepeat"
                            placeholder={RESET_PASSWORD_TEXTS.REPEAT_PASSWORD_PLACEHOLDER}
                            onChange={(e) => setPasswordRepeat(e.target.value)}
                            required
                            maxLength={255}
                            isErroneous={validationErrorRepeat}
                            errorMessage={passwordRepeatError}
                        />
                        <input type="hidden" id="email" name="email" value={email} />
                        <input type="hidden" id="guid" name="guid" value={guid ?? ''} />
                        <div className="FPbutton">
                            <Button isLoading={resettingPassword} type="submit">
                                {RESET_PASSWORD_TEXTS.BUTTON_SEND}
                            </Button>
                        </div>
                    </form>
                </div>
            )}
        </div>
    );
};

export default ResetPasswordPage;
