// import "./ValidationErrorsModal.css"

import ReactModal from "react-modal"

import api from "constants/endpoints.constants"
import { ANALYZE_WAGE_GAP } from "constants/text.constants"

import { Employees, Header } from "types/sharedTypes"

import { useEffect, useState } from "react"

import prepareCsrfToken from "utils/prepareCsrfToken"

import { fetchEmployeesInAGroup } from "services/fetchers"
import { postWithBodyAndCsrf } from "services/apiService"

import { ReactComponent as xMark } from "assets/xmark.icon.svg"

import Error from "components/atoms/error/Error"
import Button from "components/atoms/button/Button"
import RegisterTable from "components/cores/registerTable/RegisterTable"
import ContentSkeleton from "components/atoms/contentSkeleton/ContentSkeleton"

import Diagram from "./Diagram"

const headerDefaultValues = {
    Ssn: "Pnr",
    Name: "Namn",
    Number: "Anst",
    EmploymentScope: "Omf",
    WorkCode: "AA",
    GroupLevel: "G",
    Manager: "C",
    LokalKomplettering: "LL",
    Samråd: "S",
    YrkesSpecificering: "Y",
    KompetensKategori: "K",
    EmploymentStartDate: "Anstdat",
    SalaryBasic: "Grundlön",
    SalaryProvision: "Ft",
    SalaryFlexibleProvision: "Rt",
    SalaryOrganizationalAffiliation: "Org",
    CustomizableC1: "Fri1",
    CustomizableC2: "Fri2",
    CustomizableC4: "Fri4",
    CustomizableC5: "Fri5",
    CustomizableC3: "Fri3",
    CustomizableC6: "Fri6",
    CustomizableC7: "Fri7",
    CustomizableC8: "Fri8",
    CustomizableC9: "Fri9",
    CustomizableC10: "Fri10",
    IndividualAgreementsK70: "EÖ sl",
    IndividualAgreementsK71: "EÖ ls",
    IndividualAgreementsK72: "EÖ lp",
    IndividualAgreementsK73: "EÖ sp",
    Age: "Ålder",
    EmploymentLength: "Ansttid",
    Sex: "Kön",
}

type DiagramModalProps = {
    groupInfo: { Name: string; Id: string }
    registryName: string
    salaryType: number
    showModal: boolean
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>
    setShowCommentsModal: React.Dispatch<React.SetStateAction<boolean>>
}

type scatterDataObject = {
    Catagories: string[]
    SalariesMen: (string | number)[][]
    SalariesWomen: (string | number)[][]
}
type tDiagramData = scatterDataObject[]

const DiagramModal = ({ groupInfo, registryName, salaryType, showModal, setShowModal, setShowCommentsModal }: DiagramModalProps) => {
    const [employees, setEmployees] = useState<Employees[]>([])
    const [employeesTableHeaders, setEmployeesTableHeaders] = useState<Header>(headerDefaultValues)

    const [diagramData, setDiagramData] = useState<tDiagramData>([])

    const [fetchError, setFetchError] = useState("")
    const [fetchingDiagramData, setFetchingDiagramData] = useState(true)
    const [fetchingEmployeesInAGroup, setFetchingEmployeesInAGroup] = useState(true)

    useEffect(() => {
        const body = { GroupIds: [groupInfo.Id], SalaryType: salaryType, DiagramType: 2 }

        prepareCsrfToken().then((csrfToken) =>
            postWithBodyAndCsrf(api.analyzeSalaryDiagram, csrfToken, JSON.stringify(body))
                .then((response) => {
                    setDiagramData(JSON.parse(response.DiagramData))

                    setFetchingDiagramData(false)
                    setFetchError("")
                })
                .catch((err) => {
                    setFetchError(`${ANALYZE_WAGE_GAP.COMMENTS_MODAL_ERROR}${err.text()}`)
                })
        )
    }, [groupInfo.Id, salaryType])

    useEffect(() => {
        fetchEmployeesInAGroup(
            groupInfo.Id,
            setEmployees,
            setEmployeesTableHeaders,
            setFetchingEmployeesInAGroup,
            setFetchError
        )
    }, [groupInfo.Id])

    return (
        <ReactModal
            appElement={document.getElementById("root") as HTMLElement}
            isOpen={showModal}
            onRequestClose={() => setShowModal(false)}
            contentLabel={ANALYZE_WAGE_GAP.DIAGRAM_MODAL_CONTENT}
        >
            <div className="headingAndCloseButtonFlex">
                <h1>{ANALYZE_WAGE_GAP.DIAGRAM_MODAL_HEADING}</h1>
                <Button iconRight onClick={() => setShowModal(false)} Icon={xMark}>
                    {ANALYZE_WAGE_GAP.BUTTON_CLOSE}
                </Button>
            </div>
            {fetchingDiagramData && fetchingEmployeesInAGroup && !fetchError && <ContentSkeleton />}
            {fetchError && <Error>{fetchError}</Error>}
            {!fetchingDiagramData && diagramData.length && (
                <div className="maxWidth1000">
                    <Diagram
                        groupInfo={groupInfo}
                        registryName={registryName}
                        salaryType={salaryType}
                        diagramData={diagramData}
                        setShowCommentsModal={setShowCommentsModal}
                    />
                </div>
            )}
            {!fetchingEmployeesInAGroup && <RegisterTable header={employeesTableHeaders} employees={employees} />}
        </ReactModal>
    )
}

export default DiagramModal
