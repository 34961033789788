import "./EditableText.css"

import api from "constants/endpoints.constants"
import GENERAL_TEXTS from "constants/text.constants"

import { useState, useContext } from "react"

import { postWithBodyAndCsrf } from "services/apiService"

import prepareCsrfToken from "utils/prepareCsrfToken"

import TextContext from "context/TextContext"
import Button from "components/atoms/button/Button"
import Confirmation from "components/atoms/confirmation/Confirmation"

import { ReactComponent as EditIcon } from "./edit.icon.svg"

type editableTextProps = {
    pageId: string
    text: string | null | undefined
    elementType:
        | "title"
        | "paragraph"
        | "agvPhone"
        | "agvMail"
        | "agvParagraph"
        | "knowitPhone"
        | "knowitMail"
        | "knowitParagraph"
    canEdit?: boolean
}

const EditableText = ({ pageId, text, elementType, canEdit = false }: editableTextProps) => {
    const [postError, setErrorMessage] = useState("")
    const [editing, setIsEditing] = useState(false)
    const [showConfirmation, setShowConfirmation] = useState(false)

    const [newText, setNewText] = useState(text)
    const {texts, setTexts } = useContext(TextContext)
    const [updatedText, setUpdatedText] = useState("")

    const isTitle = elementType === "title"

    type htmlElProps = { children: string | null | undefined }
    // eslint-disable-next-line react/no-unstable-nested-components
    const HTMLelement = ({ children }: htmlElProps) => (isTitle ? <h1>{children}</h1> : <p>{children}</p>)

    const handleUpdateText = () => {
        const body = { pageId, textId: elementType, textValue: newText }

        prepareCsrfToken().then((csrfToken) =>
            postWithBodyAndCsrf(api.updateTexts, csrfToken, JSON.stringify(body))
                .then(() => {
                    setShowConfirmation(true)
                    if (newText) setUpdatedText(newText)
                    setTimeout(() => {
                        setShowConfirmation(false)
                    }, 5000)
                    setIsEditing((state) => !state)

                    const textsCopy = [...texts]

                    const indexToUpdate = textsCopy.findIndex(
                        (textItem) => textItem.PageId === pageId && textItem.TextId === elementType
                    )

                    // if exists in the texts array
                    if (indexToUpdate !== -1) {
                        textsCopy[indexToUpdate] = {
                            Id: textsCopy[indexToUpdate].Id,
                            PageId: pageId,
                            TextId: elementType,
                            TextValue: newText !== undefined ? newText : null,
                        }
                    }

                    setTexts(textsCopy)
                })
                .catch(() => {
                    setErrorMessage(GENERAL_TEXTS.POST_ERROR_GENERIC)
                })
        )
    }

    return (
        <>
            {postError && <div>{postError}</div>}
            {!editing && !postError && (
                <div className="elementContainer">
                    {!updatedText && <HTMLelement>{text}</HTMLelement>}
                    {updatedText && <HTMLelement>{updatedText}</HTMLelement>}
                    {canEdit && (
                        <button className="editButton" type="button" onClick={() => setIsEditing((state) => !state)}>
                            <EditIcon width="2rem" height="2rem" fill="#003A64" />
                        </button>
                    )}
                </div>
            )}
            {editing && canEdit && !postError && (
                <div className="textareaContainer">
                    <textarea className="textarea" value={newText || ""} onChange={(e) => setNewText(e.target.value)} />
                    <div className="flex gap4">
                        <Button type="submit" onClick={handleUpdateText} disabled={!newText || newText.trim() === ""}>
                            {GENERAL_TEXTS.BUTTON_SAVE}
                        </Button>
                        <Button onClick={() => {
                    setIsEditing((state) => !state);
                    setNewText(text);
                }}>{GENERAL_TEXTS.BUTTON_ABORT}</Button>
                    </div>
                </div>
            )}
            {showConfirmation && <Confirmation>{GENERAL_TEXTS.CONFIRMATION_SUCCESSFUL}</Confirmation>}
        </>
    )
}

export default EditableText
